<template>
<div class="BotonDirectorioStreamlined" :class="{right}" @click="$router.push('/jrsldir')">
<img src="assets/streamlined/DIRECTORIO.svg">
</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BotonDirectorioStreamlined",
  components: {
    
  },
  computed: {
  },
  methods: {
  },
  props: {
    right: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style scoped>

.BotonDirectorioStreamlined img {
   position: absolute;
  top: 1em;
  right: 1em;
  height: 50px;
  max-height: 5vw;
  min-height: 25px;
  cursor: pointer;
  
}

.BotonDirectorioStreamlined.right img {
  right: 0
}

</style>