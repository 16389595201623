<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <div class="ionPageContainerOuter">
    <div class="ionPageContainer">
      <ion-header>
        <ion-toolbar v-if="!isForm" class="mobileShow">
          <ion-buttons slot="start" v-if="hasBack">
            <ion-button @click="goBack">
              <ion-icon
                slot="icon-only"
                :icon="arrowBack"
                :md="arrowBack"
                :ios="chevronBack"
              ></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title>{{ pageTitle }}</ion-title>
          <ion-buttons slot="end">
            <ion-menu-button v-if="isLoggedIn"></ion-menu-button>
          </ion-buttons>
        </ion-toolbar>
        <ion-grid class="mobileHide">
          <ion-row>
            <ion-col size="6">
              <div class="logoSuperiorCyan">
                <router-link :to="isLoggedIn ? '/home' : '/login'"
                  ><img :src="esAzul ? 'assets/images/LogoSuperiorExitPolls.svg' : 'assets/images/LogoSuperiorCyan.svg'"
                /></router-link>
              </div>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="5">
              <boton-directorio-streamlined
                v-if="conDirectorio"
                :right="true"
              ></boton-directorio-streamlined>
              <div :class="breadcrumbsCyanClass" v-else>
                <div class="breadcrumbsTipo">
                  {{ pageNav1 }}
                  <span v-if="nav2 != ''" class="navsep">›</span>
                  {{ nav2 }}
                </div>
                <div class="breadcrumbsAmbito" v-if="ambito != ''">
                  {{ ambito }}
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <user-data class="mobileHideFlex large" :withLogout="true" />
      </ion-header>

      <ion-content :fullscreen="false" :class="{ burst, isForm }">
        <div class="ionContentContainerOuter">
          <div
            :class="[
              'ionContentContainer',
              iccFlex ? 'icc-flex' : false,
              iccNoPad ? 'icc-nopad' : false,
            ]"
          >
            <slot></slot>
          </div>
        </div>
      </ion-content>
      <div v-if="hasBack" class="floatingBack mobileHideBlock">
        <ion-button @click="goBack">
          <ion-icon slot="start" :icon="arrowBack"></ion-icon>
          Atrás
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonButton,
  IonButtons,
  IonMenuButton,
  alertController,
  modalController,
  //  useBackButton,
} from "@ionic/vue";
import {
  homeOutline,
  exitOutline,
  arrowBack,
  chevronBack,
} from "ionicons/icons";
import router from "@/router";
import store from "@/modules/adaptedStorage";
import { territorios } from "@/modules/cyanRegions";
import UserData from "@/components/UserData.vue";
import { Plugins } from "@capacitor/core";
import BotonDirectorioStreamlined from "./BotonDirectorioStreamlined.vue";
const { App } = Plugins;

// Utility exports

export { store, router, defineComponent, IonPage };

export default defineComponent({
  name: "CyanPageWrapper",
  components: {
    IonContent,
    IonHeader,
    IonGrid,
    IonToolbar,
    IonTitle,
    IonRow,
    IonIcon,
    IonCol,
    IonButton,
    IonButtons,
    UserData,
    IonMenuButton,
    BotonDirectorioStreamlined,
  },
  data() {
    return {};
  },
  setup() {
    //    useBackButton(10, AppComponent.androidBack);

    return {
      arrowBack,
      homeOutline,
      exitOutline,
      chevronBack,
    };
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    backRoute: {
      type: String,
      default: "",
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    nav1: {
      type: String,
      default: "",
    },
    nav2: {
      type: String,
      default: "",
    },
    ambito: {
      type: String,
      default: "",
    },
    burst: {
      type: Boolean,
      default: false,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    iccFlex: {
      type: Boolean,
      default: false,
    },
    iccNoPad: {
      type: Boolean,
      default: false,
    },
    conDirectorio: {
      type: Boolean,
      default: false,
    },
  },
  /*  beforeMount() { storeNav(this, 'beforeMount') },
  activated() { storeNav(this, 'activated') } ,
  updated() { storeNav(this, 'updated') },
  beforeRouteUpdate() { storeNav(this, 'beforeRouteUpdate') } ,*/
  computed: {
    isLoggedIn: (): boolean => store.state.isLoggedIn,
    esAzul() {
      const p = (this as any).$store.getters.userHasPermission;
      return p("busl") && !p("jrsl") && !p("pcsl");
    },
    userData: () => store.state.userData,

    hasBack(): boolean {
      return !!(this.backRoute || this.isModal);
    },

    pageTitle(): any {
      return this.title ? this.title : "Cyan";
    },

    pageNav1(): any {
      if (!this.isLoggedIn || this.nav1 == "*") return "";
      return this.nav1 ? this.nav1 : this.pageTitle;
    },
    breadcrumbsCyanClass(): string {
      return "breadcrumbsCyan" + (this.nav2 == "" ? " noNav2" : " withNav2");
    },
    ambitoUsuario() {
      const udn = (this as any).userData.nivel;
      return udn in territorios ? (territorios as any)[udn].nombre : "---";
    },
  },
  methods: {
    goBack() {
      if (this.backRoute) this.$router.replace(this.backRoute);
      else if (this.isModal) modalController.dismiss();
    },
    async androidBack() {
      if (this.backRoute) {
        router.replace((this as any).backRoute);
        return;
      }

      const alert = await alertController.create({
        header: "Salir de Cyan",
        message: "¿Desea salir de la aplicación?",
        buttons: [
          { text: "Cancelar", role: "cancel" },
          {
            text: "Aceptar",
            handler: async function () {
              App.exitApp();
            },
          },
        ],
      });
      alert.present();
    },
  },
});
</script>

<style scoped>
.logoSuperiorCyan img {
  max-height: 62px;
}
.logoSuperiorCyan {
  text-align: left;
}


.breadcrumbsCyan {
  text-align: right;
  font-weight: bold;
  color: var(--ion-color-primary);
}
ion-content,
ion-toolbar {
  --background: var(--ion-color-tertiary);
}

ion-content.isForm {
  --background: var(--ion-color-primary);
}

ion-header ion-toolbar {
  --background: var(--ion-color-primary);
  color: white;
}

ion-header ion-toolbar ion-icon,
ion-header ion-toolbar ion-menu-button {
  --color: white;
  color: white;
}
ion-content {
  position: relative;
}

ion-content .ionContentContainerOuter {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

ion-content .ionContentContainer {
  max-width: 768px;
  width: 100%;
  padding: 2vh;
  position: relative;
  margin: 0 auto;
  flex: 1 1 auto;
}

ion-content .ionContentContainer.icc-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
ion-content .ionContentContainer.icc-nopad {
  padding: 0;
}
#app .burst .ionContentContainer {
  max-width: initial;
}
.ionPageContainer,
.ionPageContainerOuter {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.ionPageContainerOuter {
  background: white;
}

.ionPageContainer {
  background: var(--ion-color-tertiary);
}

.breadcrumbsCyan {
  font-size: 120%;
}
.navsep {
  color: var(--ion-color-secondary);
}
.breadcrumbsAmbito {
  color: var(--ion-color-secondary);
  font-size: 120%;
}
.breadcrumbsCyan.noNav2 .breadcrumbsTipo {
  font-size: 150%;
  padding-top: 10px;
}

ion-router-outlet {
  padding: 5px 5px 20px;
}

.isForm {
  padding: 0px;
}

.userContainer {
  align-items: center;
  color: var(--ion-color-primary);
}
.userContainer .left {
  flex: 0 0 auto;
}

.userContainer .left img {
  max-height: 70px;
  min-height: 50px;
  border-color: var(--ion-color-tertiary);
  border-radius: 5px;
}

.userContainer .right {
  padding: 0.5em 0 0.5em 1em;
}

.bold {
  font-weight: bold;
  font-size: 110%;
}

.floatingBack {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.floatingBack ion-button {
  --color: var(--ion-color-tertiary);
  --background: var(--ion-color-primary);
  --border-radius: 20px;
}

@media (min-width: 768px) and (min-height: 500px) {
  .ionPageContainer {
    border: 0.5px solid var(--ion-color-medium);
    border-radius: 20px;
    padding: 10px 20px 20px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  ion-content .ionContentContainer {
    padding-bottom: 44px;
  }
  .ionPageContainerOuter {
    padding: 20px;
  }
  .header-md::after {
    display: none;
  }
  ion-router-outlet {
    padding: 0 110px;
  }
  .burst {
    padding: 0 20px 40px;
  }
  ion-content.isForm {
    --background: var(--ion-color-tertiary);
  }
}

[data-bocaurna="1"] .breadcrumbsCyan,
[data-bocaurna="1"] .userContainer {
  color: var(--azul-alternativo);
}

[data-bocaurna="1"] ion-content.isForm,
[data-bocaurna="1"] ion-header ion-toolbar,
[data-bocaurna="1"] .floatingBack ion-button {
  --background: var(--azul-alternativo);
}

</style>