
import { defineComponent } from "vue";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonButton,
  IonButtons,
  IonMenuButton,
  alertController,
  modalController,
  //  useBackButton,
} from "@ionic/vue";
import {
  homeOutline,
  exitOutline,
  arrowBack,
  chevronBack,
} from "ionicons/icons";
import router from "@/router";
import store from "@/modules/adaptedStorage";
import { territorios } from "@/modules/cyanRegions";
import UserData from "@/components/UserData.vue";
import { Plugins } from "@capacitor/core";
import BotonDirectorioStreamlined from "./BotonDirectorioStreamlined.vue";
const { App } = Plugins;

// Utility exports

export { store, router, defineComponent, IonPage };

export default defineComponent({
  name: "CyanPageWrapper",
  components: {
    IonContent,
    IonHeader,
    IonGrid,
    IonToolbar,
    IonTitle,
    IonRow,
    IonIcon,
    IonCol,
    IonButton,
    IonButtons,
    UserData,
    IonMenuButton,
    BotonDirectorioStreamlined,
  },
  data() {
    return {};
  },
  setup() {
    //    useBackButton(10, AppComponent.androidBack);

    return {
      arrowBack,
      homeOutline,
      exitOutline,
      chevronBack,
    };
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    backRoute: {
      type: String,
      default: "",
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    nav1: {
      type: String,
      default: "",
    },
    nav2: {
      type: String,
      default: "",
    },
    ambito: {
      type: String,
      default: "",
    },
    burst: {
      type: Boolean,
      default: false,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    iccFlex: {
      type: Boolean,
      default: false,
    },
    iccNoPad: {
      type: Boolean,
      default: false,
    },
    conDirectorio: {
      type: Boolean,
      default: false,
    },
  },
  /*  beforeMount() { storeNav(this, 'beforeMount') },
  activated() { storeNav(this, 'activated') } ,
  updated() { storeNav(this, 'updated') },
  beforeRouteUpdate() { storeNav(this, 'beforeRouteUpdate') } ,*/
  computed: {
    isLoggedIn: (): boolean => store.state.isLoggedIn,
    esAzul() {
      const p = (this as any).$store.getters.userHasPermission;
      return p("busl") && !p("jrsl") && !p("pcsl");
    },
    userData: () => store.state.userData,

    hasBack(): boolean {
      return !!(this.backRoute || this.isModal);
    },

    pageTitle(): any {
      return this.title ? this.title : "Cyan";
    },

    pageNav1(): any {
      if (!this.isLoggedIn || this.nav1 == "*") return "";
      return this.nav1 ? this.nav1 : this.pageTitle;
    },
    breadcrumbsCyanClass(): string {
      return "breadcrumbsCyan" + (this.nav2 == "" ? " noNav2" : " withNav2");
    },
    ambitoUsuario() {
      const udn = (this as any).userData.nivel;
      return udn in territorios ? (territorios as any)[udn].nombre : "---";
    },
  },
  methods: {
    goBack() {
      if (this.backRoute) this.$router.replace(this.backRoute);
      else if (this.isModal) modalController.dismiss();
    },
    async androidBack() {
      if (this.backRoute) {
        router.replace((this as any).backRoute);
        return;
      }

      const alert = await alertController.create({
        header: "Salir de Cyan",
        message: "¿Desea salir de la aplicación?",
        buttons: [
          { text: "Cancelar", role: "cancel" },
          {
            text: "Aceptar",
            handler: async function () {
              App.exitApp();
            },
          },
        ],
      });
      alert.present();
    },
  },
});
