
import { defineComponent } from "vue";

export default defineComponent({
  name: "BotonDirectorioStreamlined",
  components: {
    
  },
  computed: {
  },
  methods: {
  },
  props: {
    right: {
      type: Boolean,
      default: false
    }
  }
});
